import { ChangeDetectionStrategy, Component, Inject, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Environment, ENVIRONMENT_TOKEN, UserModel } from '@lms/shared/models';
import { GtmService } from '@lms/shared/services/app-services';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-trackers',
  templateUrl: './trackers.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TrackersComponent implements OnInit, OnChanges {
  @Input() user: UserModel | null = null;
  hotjarTrackingCode = this.environment.hotjarTrackingCode;

  constructor(
    @Inject(ENVIRONMENT_TOKEN) public environment: Environment,
    private gmtService: GtmService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.insertHotjarTrackingCode();
    this.insertRakutenCode();

    this.router.events.forEach(item => {
      if (item instanceof NavigationEnd) {
        const gtmTag = {
          event: 'page',
          pageName: item.url,
        };

        this.gmtService.pageView(gtmTag);
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.user) {
      this.gmtService.insertIdentifyingUser(this.user);
    }
  }

  private insertHotjarTrackingCode(): void {
    if (this.hotjarTrackingCode === '') {
      return;
    }

    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.innerHTML = `
      (function(h,o,t,j,a,r){
          h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
          h._hjSettings={hjid:${this.hotjarTrackingCode},hjsv:6};
          a=o.getElementsByTagName('head')[0];
          r=o.createElement('script');r.async=1;
          r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
          a.appendChild(r);
      })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
    `;
    document.getElementsByTagName('head')[0].appendChild(script);
  }

  private insertRakutenCode(): void {
    if (!this.environment.production) {
      return;
    }

    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.innerHTML = `
      (function (url) {
        /*Tracking Bootstrap Set Up DataLayer objects/properties here*/
        if(!window.DataLayer){
            window.DataLayer = {};
        }
        if(!DataLayer.events){
            DataLayer.events = {};
        }
        DataLayer.events.SPIVersion = DataLayer.events.SPIVersion || "3.4";
        DataLayer.events.SiteSection = "1";
        var loc, ct = document.createElement("script");
        ct.type = "text/javascript";
        ct.async = true; ct.src = url; loc = document.getElementsByTagName('script')[0];
        loc.parentNode.insertBefore(ct, loc);
    }(document.location.protocol + "//tag.rmp.rakuten.com/121786.ct.js"));
    `;
    document.getElementsByTagName('head')[0].appendChild(script);
  }
}
