import { NgModule } from '@angular/core';
import { TrackersComponent } from './trackers.component';
import { SharedPipesModule } from '@lms/shared/pipes';

@NgModule({
  imports: [SharedPipesModule],
  declarations: [TrackersComponent],
  exports: [TrackersComponent],
})
export class SharedUiTrackersModule {}
